<script lang="ts" setup>
import { firebaseSignOut } from '@/services/firebase-service';
import { useIonRouter, IonButton, IonIcon } from '@ionic/vue';
import { logOutOutline } from 'ionicons/icons'

const ionRouter = useIonRouter()

const handleLogout = async () => {
  console.log('handleLogout');

  // Logout via Firebase
  await firebaseSignOut()
  ionRouter.replace('/login')
}
</script>

<template>
<ion-button @click="handleLogout" class="ion-margin-top">
  <ion-icon slot="start" :icon="logOutOutline"          
  ></ion-icon>
  Log Out
</ion-button>   
</template>
