import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import App from './App.vue'
import router from './router';

import { IonicVue, createAnimation } from '@ionic/vue';

// VueForm
import Vueform from '@vueform/vueform'
import vueformConfig from './../vueform.config'


/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';
import "@ionic/vue/css/palettes/dark.class.css"; 

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

// Tailwind + Vueform
import 'tailwindcss/base.css'
import 'tailwindcss/components.css'
import 'tailwindcss/utilities.css'

import '@vueform/vueform/dist/tailwind-material.css';
// import '@vueform/signature/themes/default.css'

/* Theme variables */
import './theme/styles.css'
import './theme/variables.css';

// firebase service
import {  firebaseApp, initializeFirebase, } from './services/firebase-service';

export const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

// to get store in pinia
declare module 'pinia' {
  export interface PiniaCustomProperties {
    // router: Router;
  }
}

// const myAnimation = (baseEl:any, opts:any) => {
//   const { enteringEl, leavingEl } = opts;

//   // TODO update
//   const enteringPage = createAnimation('entering-page-animation')
//     .addElement(enteringEl)
//     .fromTo('opacity', 0, 1);

//   const leavingPage = createAnimation('leaving-page-animation')
//     .addElement(leavingEl)
//     .fromTo('opacity', 1, 0);

//   return createAnimation('root-transition')
//     .duration(500)
//     .easing('ease-in-out')
//     .addAnimation([enteringPage, leavingPage]);
// }



initializeFirebase().then((user) => {
  
  // console.log('FB user', user)
  // const fbInit = initializeFirebase()
  const app = createApp(App)
  // const pro = createProPlugin('fk-541bec5c15', inputs)
  app
  .use(IonicVue, {
    // mode: 'md',
    animated: true,
    // navAnimation: myAnimation
  })
  .use(pinia)
  .use(router)
  .use(Vueform, vueformConfig)

app.config.globalProperties.$filters = {
  capitalise(s:string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  },
  formatNumber(n:number) {
    return Intl.NumberFormat('en-AU').format(n)
  },
  formatCurrency(n:number) {
    return Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(n)
  },
  formatDate(d:Date) {
    return Intl.DateTimeFormat('en-AU', {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(d)
  }, 
  limitString(value:string, size: number) {
    if (!value) return '';
    value = value.toString();

    if (value.length <= size) {
      return value;
    }
    return value.substring(0, size) + '...';
  }
}

  router.isReady().then(() => {
    app.mount('#app');
  })
})

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $filters: {
      capitalise(s:string): String,
      formatCurrency(n:number): String,
      formatNumber(n:number): String,
      formatDate(d:Date): String,
      limitString(v:string, n: number): String,
    };
  }
}
