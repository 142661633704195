<template>
<ion-app>
  <Suspense>
    <template #default>
      <div>
        <Menu v-if="!brokerUser" contentId="app-content"></Menu>
        <PortalMenu v-else contentId="app-content"></PortalMenu>
        <ion-router-outlet 
          id="app-content"
          :animation="getAnimation"
        />
      </div>
    </template>
    <template #fallback>
        <ion-loading is-open></ion-loading>
    </template>
  </Suspense>
</ion-app>
</template>

<script setup lang="ts">
import { IonApp, IonRouterOutlet, IonLoading, createAnimation } from '@ionic/vue'
import Menu from './components/Menu.vue'
import PortalMenu from './components/PortalMenu.vue'
import { brokerUser } from '@/services/firebase-service'
import { useRoute, useRouter } from 'vue-router'
import { ref } from 'vue'

const route = useRoute()
const router = useRouter()
const admin = route.meta.requiresAdmin
const previousRoute = ref(route.path)

// Animation for forward navigation
const createForwardAnimation = (baseEl: HTMLElement, opts?: any) => {
  // Select only the main content area, excluding the header
  const enteringEl = opts.enteringEl.querySelector('ion-content');
  const leavingEl = opts.leavingEl.querySelector('ion-content');

  const enteringAnimation = createAnimation()
    .addElement(enteringEl)
    .fromTo('transform', 'translateX(100%)', 'translateX(0)')
    .fromTo('opacity', 0.1, 1);

  const leavingAnimation = createAnimation()
    .addElement(leavingEl)
    .fromTo('transform', 'translateX(0)', 'translateX(-100%)')
    .fromTo('opacity', 1, 0.1);

  return createAnimation()
    .duration(300)
    .addAnimation([enteringAnimation, leavingAnimation]);
}

// Animation for backward navigation
const createBackAnimation = (baseEl: HTMLElement, opts?: any) => {
  // Select only the main content area, excluding the header
  const enteringEl = opts.enteringEl.querySelector('ion-content');
  const leavingEl = opts.leavingEl.querySelector('ion-content');

  const enteringAnimation = createAnimation()
    .addElement(enteringEl)
    .fromTo('transform', 'translateX(-100%)', 'translateX(0)')
    .fromTo('opacity', 0.1, 1);

  const leavingAnimation = createAnimation()
    .addElement(leavingEl)
    .fromTo('transform', 'translateX(0)', 'translateX(100%)')
    .fromTo('opacity', 1, 0.1);

  return createAnimation()
    .duration(300)
    .addAnimation([enteringAnimation, leavingAnimation]);
}

// Determine which animation to use based on navigation direction
const getAnimation = (baseEl: HTMLElement, opts?: any) => {
  const isForward = isForwardNavigation(route.path);
  previousRoute.value = route.path;
  return isForward ? createForwardAnimation(baseEl, opts) : createBackAnimation(baseEl, opts);
}

// Helper function to determine navigation direction
const isForwardNavigation = (currentPath: string) => {
  // Add your route order logic here
  const routeOrder = [
    '/login',
    '/dashboard',
    '/application/:id/mortgage',
    '/application/:id/applicants',
    '/application/:id/assets-liabilities',
    '/application/:id/document-upload',
    '/application/:id/review',
    '/application/:id/summary'
  ];

  const getCurrentRouteIndex = (path: string) => {
    return routeOrder.findIndex(route => {
      // Convert route pattern to regex to match dynamic segments
      const pattern = route.replace(/:id/g, '[^/]+');
      return new RegExp(`^${pattern}$`).test(path);
    });
  };

  const prevIndex = getCurrentRouteIndex(previousRoute.value);
  const currentIndex = getCurrentRouteIndex(currentPath);

  return currentIndex > prevIndex;
}
</script>

<style>
/* Prevent black flash during transitions */
ion-content {
  --background: white;
}

/* Keep header stable during transitions */
ion-header {
  z-index: 100;
  position: relative;
}

/* Ensure animations work smoothly */
ion-router-outlet {
  contain: none;
}

/* Optional: Add some transition styles */
.ion-page {
  background: var(--ion-background-color);
}
</style>
