import { defineStore } from 'pinia'
import { db, firestoreDeleteDocument, firestoreUpsertSubcollection, currentUser, userUuid, fireBaseGetUserData, firestoreUpsertReview, brokerUser, firestoreUpdateApplicants, firestoreBrokerUpdateArchive, brokerAdministrator, firestoreAddBroker, firestoreUpdateBroker, firestoreRemoveAccess, firestoreEnableBrokerAccess, firestoreAddApplicant  } from '@/services/firebase-service'
import { ComputedRef, Ref, computed, reactive, ref, watch, watchEffect } from 'vue'
import { collection, collectionGroup, doc, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import { v4 as uuid } from 'uuid'
import { presentToast } from './fed-services'
// import { useRoute } from 'vue-router'

export const useCombinedStore = defineStore('applicant', () => {
	// const route= useRoute()

	const mortgage_applications: Ref = ref()
	const mortgage_applications_steps: Ref = ref()

	const collections: Ref = ref({
		credit_cards: [],
		bank_statements: [],
		identification: [],
		employers: [],
		addresses: [],
		applicants: [],
		bonuses: [],
		documents: [],
		broker: [],
	})

	// TODO: Add loading to all pages see LoadingWrapper.vue
	const isLoading: Ref = ref(false)
	
	const requiredDocumentNumber: Ref = ref({
		credit_cards: 0,
		bank_statements: 3,
		identification: 1,
		employers: 3,
		vehicles: 0,
		properties: 0,
		assets: 0,
		loans: 0,
		documents: 0,
		addresses: 1, 
		applicants: 1, 
		income_other: 0, 
		bonuses: 2,
	})

	const unsubs: Ref = ref({})


	interface ProgressInterface {
		[name: string]: number
	  }
	
	const helpers = ref({
		progressIndicators:<ProgressInterface> {
	},
		// TODO remove these - but they are linked to identity atm??
		progressIndicator: {
			identification: 0,
		},
		pageId: "" as string,
		totalApplications: null as number | null,
		aiReviewComplete: false,
		loading:  false,
		
	})

	// Single source of truth for application data
	const applications = ref<Record<string, any>[]>([])
	const currentApplication = ref<Record<string, any>>({})

	// Computed property to get current application based on pageId
	const getCurrentApplication = computed(() => {
		if (!helpers.value.pageId || !applications.value) return {}
		return applications.value.find(app => app.id === helpers.value.pageId) || {}
	})

	// Single method to fetch all applications
	const fetchApplications = async () => {
		const data = await fireBaseGetUserData()
		applications.value = data
		
		// Update steps data
		mortgage_applications_steps.value = data.reduce((acc: any, item: any) => {
			acc[item.id] = { steps: item.steps }
			return acc
		}, {})
	}

	// Method to update current application
	const updateCurrentApplication = (val: Record<string, any>) => {
		currentApplication.value = { ...currentApplication.value, ...val }
	}

	// get all mortgage applications 
  	const getMortgages = async (subcollection:string) => {
		return new Promise(function(resolve, reject) {
			const q = query(
				collection(db, `users/${userUuid.value}/applications`),
				orderBy('update_timestamp') 
			)

		unsubs.value[subcollection] = onSnapshot(
		q,  
		async (snapshot) => {		  
			const data = snapshot.docs.map((d) => {
				return {id: d.id, ...d.data()}
			})

			mortgage_applications.value = data

			// work out the steps
			mortgage_applications_steps.value =  await data.reduce((accumulator:any, item:any) => {
				const category = item.id;
				accumulator[category] = {steps: item.steps}
				return accumulator
				}, {})
				
			resolve(true)
		},
		(error) => {
			console.log('error')
			reject(error)
		})
    })
  }
	
  	const getSubcollection = async (subcollection:string) => {
		return new Promise(function(resolve, reject) {
			const q = query(
				collection(db, `/users/${userUuid.value}/applications/${helpers.value.pageId}/${subcollection}/`), 
				// Ensure all documents contain a create_timestamp
				orderBy('create_timestamp') 
			)
		
			unsubs.value[subcollection] = onSnapshot(
			q,  
			async (snapshot) => {
				// get different types out of uploaded documents
				if (subcollection === 'documents') {
					collections.value['credit_cards'] = []
					collections.value['bank_statements'] = []
					collections.value['identification'] = []
					collections.value['employers'] = []
					collections.value['bonuses'] = []
					collections.value['documents'] = []

					snapshot.docs.forEach(doc => {
						// create an array for each type of document
						const type = doc.data().type
						collections.value[type].push(doc.data())
						collections.value['documents'].push(doc.data())
						
					})
				} else {
					collections.value[subcollection] = []
					snapshot.docs.forEach(doc => {
						collections.value[subcollection].push(doc.data())
					})
					if (subcollection === 'broker') {
						console.log('broker', collections.value[subcollection])
					}
				}
				resolve(true)
      },
			(error) => {
				console.log('error')
				reject(error)
			})
    })
  }
	
  

  // TODO : combine three reviews into one function
	const getAiReview = async () => {
			try {
				const docRef = doc(db, `/users/${userUuid.value}/applications/${helpers.value.pageId}/reviews/ai`)
		
				// TODO: handle unsub
				unsubs.value['reviewAi']  = onSnapshot(docRef, (doc) => {
					collections.value.reviewAi = doc.data()
				})

			} catch(e) {
				console.error(e)
			}		
	}
	
	const getUserReview = async () => {
		return new Promise(function(resolve, reject) {
			const docRef = doc(db, `/users/${userUuid.value}/applications/${helpers.value.pageId}/reviews/user`)
	

			unsubs.value['reviewUser'] = onSnapshot(
				docRef,  
				async (snapshot) => {
					// get different types out of uploaded documents
					collections.value.reviewUser = snapshot.data()
					resolve(true)
				},
				(error) => {
					console.log('error')
					reject(error)
				})
		})
	}

	const getBrokerReview = async () => {
		return new Promise(function(resolve, reject) {
			const docRef = doc(db, `/users/${userUuid.value}/applications/${helpers.value.pageId}/reviews/broker`)
	


			unsubs.value['reviewBroker'] = onSnapshot(
				docRef,  
				async (snapshot) => {
					// get different types out of uploaded documents
					collections.value.reviewBroker = snapshot.data()
					resolve(true)
				},
				(error) => {
					console.log('error')
					reject(error)
				})
		})
	}
	
const saveReview = async (formData:any, type: string, user:boolean = true) => {
	try {
		
		console.log('typeSaveReview', type)
		helpers.value.loading = true

		const result: any = await firestoreUpsertReview(formData, type, user)
		
		if (result.error) {
			console.log('error', result.error)

			await presentToast(result.error, 'danger',  10000, 'middle')
		} else {
			console.log('success', result)

			// refresh data.
		}
		helpers.value.loading = false
	} catch (error:any) {
		console.log('error', error)
		await presentToast(error, 'danger',  10000, 'middle')
		helpers.value.loading = false
	}
}

  const add = async (type: string, applicant_id?: string, eh_id?: string) => {
		const pushedData=	applicant_id &&  eh_id? {id: null, applicantId: applicant_id, ehId: eh_id} : {id: null, applicantId: applicant_id}
		// console.log('eheee', pushedData)

		await collections.value[type].push(pushedData)
  }

  const save = async (formData:any, type: string) => {
    try {
		// check for id
		if (!formData.id) {
			formData.id = uuid()
		}

		// TODO: Last value is always false, can now be removed
		const result = await firestoreUpsertSubcollection(formData, "applicants", helpers.value.pageId!, type, formData.id, false)
      
		if (type === 'applicants') {
			// add applicant here
			await firestoreUpdateApplicants()
		} 

		if (result.error) {
		return await presentToast(result.error, 'danger',  10000, 'middle')
		} 
		
		return result
			
    } catch (error:any) {
    	return await presentToast(error, 'danger',  10000, 'middle')
    }
  }

  const deleteDoc = async (id: string, type: string ) => {
    try {
			if (id) {
				const deleteResult = firestoreDeleteDocument("applicants", 
				helpers.value.pageId!, 
				type, 
				id)
				
			} else {
				await collections.value[type].pop({id:null})
			}
		} catch (error:any) {
			return await presentToast(error, 'danger',  10000, 'middle')
		}
  }

  const cancel = async ( id: string, type: string) => {
		if (!id) {
    	await collections.value[type].pop({id:null})
		}
  }

	// get names and id's of borrowers used (for select inputs)
	const borrowerList: ComputedRef = computed(() => {
		return collections.value.applicants.map((doc: any) => {
			return {
				value: doc.id,
				label: doc.full_name,
			}
		})
  })
	const addressList: ComputedRef = computed(() => {
		return collections.value.addresses.map((doc: any) => {
			return doc.address
		})
  })

	// get names and id's of borrowers used (for select inputs)
	const singleBorrower = computed(() => {
		if (collections.value.applicants?.length === 1) {
			return [collections.value.applicants[0].id]
		}
		return []
	})


	const getApplicantName = ((ids: Array<string>) => {
		let dataArray = []
		for (const id of ids) {
			// console.log('id', id)
			for (const applicant of collections.value.applicants) {
				// console.log('applicant', id, applicant.id)
				if (id === applicant.id) {
					dataArray.push(applicant.full_name)
				}
			}
		}
		return dataArray
	})

	const checkDocumentsComplete: ComputedRef = computed(() => { 
		const matchingObjects = compareObjects(['bank_statements', 'credit_cards', 'identification', 'employers', 'bonuses'])

		return { 
			// make an array of matchingObjects then check if it includes false !
			// submitDisabled: Object.values(matchingObjects).includes(false),
			...matchingObjects,
		}
	})


	watch(
		()=> collections.value.reviewAi,
		(review)=> {
			return helpers.value.aiReviewComplete = true
		}
	)

	const docUploadComplete = computed(()=>{
		return mortgage_applications_steps.value[helpers?.value?.pageId!]?.steps?.includes('document-upload')
	})
	const userReviewComplete = computed(()=>{
		return mortgage_applications_steps.value[helpers?.value?.pageId!]?.steps?.includes('completed')
	})

	function compareObjects( filter:Array<string>) {
		const returnData:{ 
			[index: string]: any,  
		} = []	
	
		collections.value.applicants.forEach((el:any) => {
			// create array by application id
			returnData[el.id] = {}

			// add all types to be checked from filter
			for (const value of filter.values()) {
				if (value != 'bonuses' && value != 'employers') {
					returnData[el.id] = {...returnData[el.id], [value]: {}}

					// add count 
					returnData[el.id][value] = {
						expected: requiredDocumentNumber.value[value],
						actual: countDocs(el, value, ),
						complete: collections.value[value].length >= requiredDocumentNumber.value[value] ? true : false
					}
				} else {
					returnData[el.id] = {...returnData[el.id], [value]: []}
				}
			}
		})

		// loop over employment_history to add ids for employers and bonuses
		collections.value.employment_history.forEach((eh:any) => {
			// match on owners array					
			if (Object.keys(returnData).includes(eh.owners[0]) ) {
				// add to returnData array

				let actual = countDocs(eh, 'employers', )
				returnData[eh.owners[0]].employers.push({
					id: eh.id,
					expected: requiredDocumentNumber.value.employers,
					actual: actual,
					complete: actual >= requiredDocumentNumber.value.employers ? true : false
				})

				// add bonuses
				if(eh.bonus === 'true') {
					countDocs(eh, 'bonuses')
					returnData[eh.owners[0]].bonuses.push({
						id: eh.id, 
						expected: requiredDocumentNumber.value.bonuses,
						actual: actual,
						complete: actual >= requiredDocumentNumber.value.employers ? true : false
					})
				}
			}
		})	
		

		returnData.applicantCheck = checkAllValues(returnData)
		// returnData.checkApplicantIdComplete = checkApplicantIdComplete(returnData)
		// TODO: Rework this mess

		return returnData
	}

	function checkAllValues(data: any) {
		// console.log('eleee', data)
		let passed = false
		const employerCheck:any = []
		const bonusCheck:any = []
		const otherCheck:any = []

		const appIdData = {}
		for (const key in data)
		{
			const indexedItem = data[key]
			// console.log('eleee', Object.values(indexedItem['employers']))
			// TODO: Check for complete in The employers and bonuses arrays
			Object.values(indexedItem['employers']).forEach((emp:any) => {
				employerCheck.push(Object.values(emp).includes(false) ? false : true)
			})
			Object.values(indexedItem['bonuses']).forEach((emp:any) => {
				bonusCheck.push(Object.values(emp).includes(false) ? false : true)
			})

			for (const key2 in indexedItem) {
				const indexedItem2 = indexedItem[key2]

				// check if any false values
				otherCheck.push(Object.values(indexedItem2).includes(false) ? false : true)
		
			}

		}
		// check if no false values, 
		if (!Object.values(bonusCheck).includes(false) && !Object.values(employerCheck).includes(false) && !Object.values(otherCheck).includes(false)) {
			passed = true
		}
		// console.log('appIdData', appIdData)
		return passed 
	}

	
	function countDocs(data: any, type: string) {
		let count = 0
		if (type === 'employers' || type === 'bonuses') {
			collections.value[type].forEach((el:any) => {
				el.ehId === data.id ? count++ : count
			})
	 	} else {
			collections.value[type].forEach((el:any) => {
				// console.log('el.id', el.id, data.id)
				el.applicantId === data.id ? count++ : count
			})
		}
		return count
	}

	// function checkApplicantIdComplete (data: any){
		
	// }

	const hasDocuments: ComputedRef = computed(()=> {
		// check if applications has any documents
		return collections.value.documents.length > 0
	})


	const breadCrumbActive = computed(()=> {
		const returnData:any = {}
		// get all steps
		for (const key  in mortgage_applications_steps.value)
		{
			const item = mortgage_applications_steps.value[key]
	
			// return object of all active and disabled steps
			if (item.steps?.includes('created')) {
			returnData[key] = { show: {'created':true}, active: 'mortgage'}
			} 
			if (item.steps?.includes('mortgage')) {
			returnData[key] = { ...returnData[key], show: { ...returnData[key].show, 'mortgage':true},  active: 'applicants'}
			}
			if (item.steps?.includes('applicants')) {
			returnData[key] = { ...returnData[key], show: { ...returnData[key].show, 'applicants':true},  active: 'assets-liabilities'}
			}
			if (item.steps?.includes('assets-liabilities')) {
			returnData[key] = { ...returnData[key], show: { ...returnData[key].show, 'assets':true},  active: 'document-upload'}
			}  
			if (item.steps?.includes('document-upload')) {
			returnData[key] = { ...returnData[key], show: { ...returnData[key].show, 'documents':true}, active: 'review'}
			}
			if (item.steps?.includes('completed')) {
			returnData[key] = { ...returnData[key], show: { ...returnData[key].show, 'completed':true}, }
			}
			// if (item.steps?.includes('review')) {
			// returnData[key] = { ...returnData[key], show: { ...returnData[key].show, 'review':true}, active: 'review'}
			// }
		}
			return returnData
		})

	const initStore = async () => {
		// check if already subscribed if not subscribe
		// unsubs?.value?.applicants ? undefined : await getAiReview()
		unsubs?.value?.applicants && !brokerUser ? undefined : await getSubcollection('applicants')
		unsubs?.value?.addresses && !brokerUser ? undefined : await getSubcollection('addresses')
		unsubs?.value?.broker && !brokerUser ? undefined : await getSubcollection('broker')
		unsubs?.value?.employment_history && !brokerUser ? undefined : await getSubcollection('employment_history')
		unsubs?.value?.income_other && !brokerUser ? undefined : await getSubcollection('income_other')
		unsubs?.value?.vehicles && !brokerUser ? undefined : await getSubcollection('vehicles')
		unsubs?.value?.properties && !brokerUser ? undefined : await getSubcollection('properties')
		unsubs?.value?.assets && !brokerUser ? undefined : await getSubcollection('assets')
		unsubs?.value?.loans && !brokerUser ? undefined : await getSubcollection('loans')
		unsubs?.value?.documents && !brokerUser ? undefined : await getSubcollection('documents')
	}


	  // Actions
	  const initializeBreadCrumb = async (applicationId: string) => {
		if (!breadCrumbActive.value[applicationId]) {
		  breadCrumbActive.value[applicationId] = {
			show: {
			  created: true,
			  mortgage: false,
			  applicants: false,
			  assets: false,
			  documents: false
			},
			active: 'mortgage'
		  }
		}
	  }

  return { 
    	collections,
		requiredDocumentNumber,
		borrowerList,
		addressList,
		singleBorrower,
		helpers,
		mortgage_applications_steps,
		hasDocuments,
		breadCrumbActive,
		initializeBreadCrumb,
		updateCurrentApplication,
		getMortgages,
		getSubcollection,
		getAiReview,
		getUserReview,
		getBrokerReview,
		saveReview,
		add,
		save, 
		deleteDoc,
		cancel,
		getApplicantName,
		initStore,
		checkDocumentsComplete,
		mortgage_applications,
		currentApplication,
		unsubs,
		docUploadComplete,
		userReviewComplete,
		applications,
		getCurrentApplication,
		fetchApplications,
		isLoading,
  }
})

export const useSettings = defineStore( "settings", () => {

	const darkMode = ref(false)   
	
	return { darkMode }
},
{
	persist: true,
}
)

export const usePortal = defineStore( "portal", () => {

	const unsubs: Ref = ref({})
	const brokerData: Ref = ref()
	const brokerApplications: Ref = ref()
	const brokers: Ref = ref([])
	

	const fetchBrokerData: ComputedRef = computed(() => {
		return {
			brokerName: currentUser.value?.displayName,
			brokerage: import.meta.env.VITE_BROKERAGE,
			brokerId: userUuid.value,
		}
	})
	

	
	const getBrokerApplications = async () => {
		return new Promise(function(resolve, reject) {
			const q = query(
				collectionGroup(db, 'applications'),
				where('brokerage', '==', 'au-first-street'),
				where('brokers', 'array-contains-any', [userUuid.value])
			  );

			unsubs.value['brokerApplications'] = onSnapshot(
				q,  
				async (snapshot) => {
					// get different types out of uploaded documents
					brokerApplications.value = snapshot.docs.map((d) => {
						return { id: d.id, ...d.data() };
					})
					// brokerApplications.value = []
					// snapshot.docs.forEach(doc => {
					// 	brokerApplications.value.push(doc.data())
					// })

					resolve(true)
				},
				(error) => {
					console.log('error')
					reject(error)
				})
		})
	}

	// Method to fetch brokers
	const fetchBrokers = async () => {
		if (!brokerAdministrator.value) {
			throw new Error('Unauthorized: Only broker administrators can access this data.')
		  }
		return new Promise(function(resolve, reject) {
			const q = query(
				collection(db, `brokerages/${import.meta.env.VITE_BROKERAGE}/brokers`),
				where('brokerage', '==', import.meta.env.VITE_BROKERAGE),
				orderBy('archived', 'asc'),
				orderBy('brokerName', 'asc')
			);

			unsubs.value['users'] = onSnapshot(
				q,
				async (snapshot) => {
					brokers.value = snapshot.docs.map((d) => {
						return { id: d.id, ...d.data() };
					})
					resolve(true)
				},
				(error) => {
					console.log('error')
					reject(error)
				}
			)
		})
	}

	const archiveApplication = async (application_id: string, userID: string, archived: boolean) => {
		console.log('archiveApplication', application_id)
		// await firestoreUpsertSubcollection(id, 'applications', id, 'archived', '', true)	
		await firestoreBrokerUpdateArchive(application_id, userID, archived)
	}

	const addBroker = async (formData:any) => {
		console.log('addBroker', formData)
		try {
			return await firestoreAddBroker(formData)
		} catch (error) {
			console.log('error', error)
			return {
				success: false,
				error: error
			}
		}
	}	

	const editBroker = async (formData:any) => {
		console.log('editBroker', formData)
		return await firestoreUpdateBroker(formData)
	}

	const removeAccess = async (brokerId: string) => {
		console.log('removeAccess', brokerId)
		return await firestoreRemoveAccess(brokerId)
		
	}

	const enableBrokerAccess = async (brokerId: string) => {
		console.log('enableBrokerAccess', brokerId)
		return await firestoreEnableBrokerAccess(brokerId)
	}

	const addApplicant = async (formData:any) => {
		const response = await firestoreAddApplicant(formData)
		console.log('addApplicant', response)
		return response

	}
	
	return { 
		getBrokerApplications,
		fetchBrokers,
		archiveApplication,
		addBroker,
		editBroker,
		removeAccess,
		enableBrokerAccess,
		addApplicant,
		fetchBrokerData,
		brokerData,
		brokerApplications,
		brokers,
		unsubs,
	 }
}
)