<template>
  <component :is="elementLayout" ref="container">
    <template #element>
      <!-- Use `button` tag -->
      <template v-if="buttonType === 'button'">
        <!-- Use it as a component -->
        <ion-button
          v-if="buttonLabel && isButtonLabelComponent"
          v-bind="{
            ...button,
            ...aria,
          }"
          :tabindex="isDisabled || isLoading ? -1 : undefined"
          :disabled="isDisabled"
          @click.prevent="handleClick"
        >
          <component :is="buttonLabel"/>
        </ion-button>

        <!-- Use it as HTML -->
        <ion-button
          v-else-if="buttonLabel"
          v-bind="{
            ...button,
            ...aria,
          }"
          :fill="filled"
          :tabindex="isDisabled || isLoading ? -1 : undefined"
          :disabled="isDisabled"
          @click.prevent="handleClick" 
        >
        <ion-icon v-if="icon" slot="start" :icon="icon"></ion-icon>
        {{ resolvedButtonLabel }}
      </ion-button>

        <!-- Use it as slot -->
        <button
          v-else
          v-bind="{
            ...button,
            ...aria,
          }"
          :tabindex="isDisabled || isLoading ? -1 : undefined"
          :class="classes.button"
          :disabled="isDisabled"
          @click.prevent="handleClick"
        ><slot :el$="el$"><component :is="fieldSlots.default" :el$="el$"/></slot></button>
      </template>

      <!-- Use `anchor` tag -->
      <template v-else>
        <!-- Use it as a component -->
        <a
          v-if="buttonLabel && isButtonLabelComponent"
          v-bind="{
            ...button,
            ...aria,
          }"
          :tabindex="isDisabled || isLoading ? -1 : undefined"
          :class="classes.button"
          @click="handleClick"
        >
          <component :is="buttonLabel"/>
        </a>

        <!-- Use it as HTML -->
        <a
          v-else-if="buttonLabel"
          v-bind="{
            ...button,
            ...aria,
          }"
          v-html="resolvedButtonLabel"
          :tabindex="isDisabled || isLoading ? -1 : undefined"
          :class="classes.button"
          @click="handleClick"
       ></a>

        <!-- Use it as slot -->
        <a
          v-else
          v-bind="{
            ...button,
            ...aria,
          }"
          :tabindex="isDisabled || isLoading ? -1 : undefined"
          :class="classes.button"
          @click="handleClick"
        ><slot :el$="el$"><component :is="fieldSlots.default" :el$="el$"/></slot></a>
      </template>
    </template>

    <!-- Default element slots -->
    <template v-for="(component, slot) in elementSlots" #[slot]><slot :name="slot" :el$="el$"><component :is="component" :el$="el$"/></slot></template>
  </component>
</template>
<script lang="ts">
  // TODO: check for future TS support, remove nocheck below
  //@ts-nocheck
  import { defineElement, ButtonElement } from '@vueform/vueform'
  import { classes } from '@vueform/vueform/dist/tailwind-material'
  import {ref, toRefs} from 'vue'
	import { IonButton } from '@ionic/vue'
  import { saveOutline, calendarOutline } from "ionicons/icons";

  export default defineElement({
    ...ButtonElement, // adding props, mixins, emits
    name: 'CustomButtonElement',
    props: {
      ...ButtonElement.props,
      filled: {
        type: String,
        required: false,
        default: 'solid',
      },
      icon: {
        // type: any,
        required: false,
        // default: saveOutline,
      }
    },
    components: {
      IonButton,
      
    },
    setup(props:any, context:any) {
      const element = ButtonElement.setup(props, context)
      const defaultClasses = ref({
        ...classes.ButtonElement,
      })
      return {
        defaultClasses,
        ...element,
        saveOutline,
        calendarOutline,
      }
    }
  })
</script>