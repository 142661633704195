import {  
    toastController
} from '@ionic/vue'
import { Loader } from "@googlemaps/js-api-loader"
import { Ref } from 'vue';
import { useCombinedStore } from "./pinia-store"
import { useRouter } from 'vue-router'


/* Present ionic Toasts */
export const presentToast = async (message: string, color?: string, duration?: number, position?: 'top' | 'middle' | 'bottom') => {
    const finalDuration = duration || 5000;
    const toast = await toastController.create({
        message: message,
        duration: finalDuration + 500, // Add extra time for exit animation
        color: color || 'success',
        position: position || 'top',
        cssClass: 'sliding-toast',
        animated: true
    });

    await toast.present();

    // Add leaving class just before toast disappears
    setTimeout(() => {
        const toastEl = document.querySelector('ion-toast.sliding-toast');
        if (toastEl) {
            toastEl.classList.add('toast-leaving');
        }
    }, finalDuration); // Start exit animation at the end of the duration
}

export const loadGooglePlaces = async (inputId: HTMLInputElement, formRef: any, type:string = 'address' ) => {
  // Don't load google in dev
  // console.log('inputId', inputId)
  // if (import.meta.env.DEV) {
  //   return true
  // }
  // TODO: Add back the above before deploying
  const loader = new Loader({
    apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    version: "weekly",
  })
  
  const Places = await loader.importLibrary('places')      
            
  const options = {
    types: [type], // address or establishment
    locationRestriction: { country: "au" }, 
    fields: ["address_components", "formatted_address", "name"],
    strictBounds: false,
    }
  
  // per the Google docs create the new instance of the import above.
  const autocomplete = await new Places.Autocomplete(inputId, options)
  await autocomplete.getPlace()
  
  //add the place_changed listener to display results when inputs change
  autocomplete.addListener('place_changed', () => {
    const place = autocomplete.getPlace();
    // account for business addresses
    if (type === 'establishment') {
      formRef.el$(inputId.id).update(place.name)
      // update the employer_address field
      formRef.el$(`${inputId.id.split('.', 2).join('.')}.employer_address`).update(place.formatted_address)
    } else {
      formRef.el$(inputId.id).update(place.formatted_address)
    }

  })
    
  return true
}


// export const uploadAiFiles =  async (event: any, pageId:string) => {
//   console.log('submitHandler', event)
//   // console.debug('event.target.files', event.target.title)
//   // if (event.target.files.length) {
//   //   // We need to submit this as a multipart/form-data
//   //   // to do this we use the FormData API.
//   //   const body = new FormData()
//   //   // We can append other data to our form data:
//   //   // body.append('name', data.name)
//   //   // Finally, we append the actual File object(s)
//   //   console.log(event.target.files[0].name,'event.target.files')

//   //   const files: ReadonlyArray<File> = [...(event.target.files ? event.target.files : [])];

//   //   files.forEach((file) => {
//   //       body.append('license', file)
//   //       // console.log(`File: ${file.name}`);
//   //   });
    
//     // return await firebaseStorageUpload(event, 'test', pageId )
//   // }
// }

// export const createApplicationIds = async (formData: any) => {

//     const store = useStore()

//     // create application id's for each applicant
//     if (formData.number_of_applicants > store.helpers.applicants.ids.length ) {
//         let counter = store.helpers.applicants.ids.length
//         while (counter < formData.number_of_applicants) {
//           store.helpers.applicants.ids.push(uuid())
//           counter++
  
//         }
//       } else {
//         console.debug('formfields', formData.number_of_applicants,  store.helpers.applicants.ids.length)
//         let counter = store.helpers.applicants.ids.length
//         while (counter > formData.number_of_applicants) {
//           store.helpers.applicants.ids.pop()
//           counter--
  
//         }
//       }
// }

// // handle all state changes on login
// export const checkApplicationProgress = async (data:any) => {
//     //check whether existing user and either push to dash or to PreliminaryQuestions page
//     console.debug('checkApplicationProgress' )

//     // TODO: Redirect to dashboard page if more than one application 
//     // check if there is only one mortgage application and redirect to the appropriate page
//     if (data.length === 1) {
//         console.debug('1')
//         return `preliminary-questions/${data._rawValue.mortgage_applications[0].id}`

//     } // No existing user redirect to Prelim Questions
//     else {
//         const id = uuid()
//         return `preliminary-questions/${id}`
//     }
//   }
