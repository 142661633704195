<template>
 <ion-menu side="end" :content-id="props.contentId">
    <ion-buttons slot="end">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-header>
      <ion-toolbar>
      <ion-title>Menu</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-list>
        <!-- <ion-item>
          <ion-toggle name="setting3" color="secondary" checked>Setting 3</ion-toggle>
        </ion-item>  -->
        <ion-item>
          <ion-toggle name="blackberry" :checked="paletteToggle" @ionChange="toggleChange($event)" color="dark">Dark Mode</ion-toggle>
        </ion-item>
      </ion-list>
      
      <LogOut></LogOut>
      
    </ion-content>
  </ion-menu> 
</template>

<script setup lang="ts">
  import { IonHeader, IonToolbar, IonTitle, IonButton, IonButtons, 
    IonBackButton, IonNavLink, IonMenu, IonMenuButton,IonContent, IonPage, IonItem, IonToggle, IonList, ToggleCustomEvent } from '@ionic/vue';
  import LogOut from '@/components/LogOut.vue';
  import { onBeforeMount, onMounted, ref } from 'vue'
  import { useSettings } from '@/services/pinia-store'

  const props = defineProps(['contentId'])
  const settings = useSettings()
  const darkModePreference = settings.darkMode


  onBeforeMount(() => {
    document.body.classList.toggle('dark', darkModePreference)
    document.documentElement.classList.toggle('ion-palette-dark', darkModePreference);
  })

  // dark theme adjustment
  const paletteToggle = ref(false);

  // Add or remove the "ion-palette-dark" class on the html element
  const toggleDarkPalette = (shouldAdd:any) => {
    document.body.classList.toggle('dark', shouldAdd)
    document.documentElement.classList.toggle('ion-palette-dark', shouldAdd);
  }

  // Check/uncheck the toggle and update the palette based on isDark
  const initializeDarkPalette = (isDark:any) => {
    paletteToggle.value = isDark;
    toggleDarkPalette(isDark);
  }

  // Initialize the dark palette based on the initial
  // value of the prefers-color-scheme media query
  initializeDarkPalette(settings.darkMode)


  // Listen for the toggle check/uncheck to toggle the dark palette
  const toggleChange = (ev: ToggleCustomEvent) => {
    settings.darkMode = ev.detail.checked
    toggleDarkPalette(ev.detail.checked)
  }
</script>