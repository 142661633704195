import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw, RouterView, START_LOCATION  } from 'vue-router'
import {pinia} from '@/main'

const Login = () => import('../views/Login.vue')
const Dashboard = () => import('../views/Dashboard.vue')
const PreliminaryQuestions = () => import('../views/PreliminaryQuestions.vue')
const ApplicantDetails = () => import('../views/ApplicantDetails.vue')
const TestPage = () => import('../views/TestPage.vue')
const DocumentUpload = () => import('../views/DocumentUpload.vue')
const Review = () => import('../views/Review.vue')
const AssetsLiabilities = () => import('../views/AssetsLiabilities.vue')
const Summary = () => import('../views/Summary.vue')
const PortalDashboard = () => import('@/views/portal/PortalDashboard.vue')
const PortalReview = () => import('@/views/portal/PortalReview.vue')
const PortalAdministration = () => import('@/views/portal/PortalAdministration.vue')
const PortalView = () => import('@/views/portal/PortalView.vue')

import { currentUser, brokerUser, userUuid, firebaseCreateCollection } from '@/services/firebase-service';
import { useCombinedStore, usePortal } from '@/services/pinia-store'
import { h } from 'vue'
import {v4 as uuid} from 'uuid'

const COMPANY_NAME = import.meta.env.VITE_APP_COMPANY_NAME

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: Login,
    meta: {
      requiresAuth : false,
      title: `${COMPANY_NAME}: Login`
    },
  },
  // login check
  {
    path: '/login-check',
    component: async () => h(RouterView),
    props: true,
    meta: {
      requiresAuth : true,
      title: `${COMPANY_NAME}: Login Check`
    }
  },
  {
    path: '/test',
    component: TestPage,
    meta: {
      requiresAuth : false,
      title: `${COMPANY_NAME}: Application`
    },
  },
  {
    path: '/dashboard',
    component: Dashboard,
    meta: {
      requiresAuth : true,
      title: `${COMPANY_NAME}: Dashboard`
    }
  },

  // application pages
  {
    path: '/application/:id',
    // redirect: '/login',
    component: async () => h(RouterView),
    props: true,
    meta: {
      requiresAuth : true,
      title: `${COMPANY_NAME}: Application`
    },
    children: [
    {
      path: 'mortgage',
      component: PreliminaryQuestions,
      meta: {
        requiresAuth : true,
        title: `${COMPANY_NAME}: Application - Preliminary Questions`
      }
    },
    {
      path: 'applicants',
      component: ApplicantDetails,
      meta: {
        requiresAuth : true,
        title: `${COMPANY_NAME}: Application - Applicant Details`
      },
    },
    {
      path: 'assets-liabilities',
      // redirect: '/login',
      component: AssetsLiabilities,
      props: true,
      meta: {
        requiresAuth : true,
        title: `${COMPANY_NAME}: Add all your assets and liabilities`
      },
    },
    {
      path: 'document-upload',
      // redirect: '/login',
      component: DocumentUpload,
      props: true,
      meta: {
        requiresAuth : true,
        title: `${COMPANY_NAME}: Provide required documentation`
      },
    },
    {
      path: 'review',
      // redirect: '/login',
      component: Review,
      props: true,
      meta: {
        requiresAuth : true,
        title: `${COMPANY_NAME}: Review your application`
      },
    },
    {
      path: 'summary',
      // redirect: '/login',
      component: Summary,
      props: true,
      meta: {
        requiresAuth : true,
        title: `${COMPANY_NAME}: Review your application`
      },
    },
  ],
  }, 

  // Broker pages
  {
    path: '/broker',
    component: async () => h(RouterView),
    props: true,
    meta: {
      requiresAdmin : true,
      title: `${COMPANY_NAME}: Broker Portal`
    },
    children: [
      {
        path: 'dashboard',
        component: PortalDashboard,
        meta: {
          requiresAdmin : true,
          title: `${COMPANY_NAME}: Broker Portal: Dashboard`
        }
      },
      {
        path: 'user/:uuid/application/:id',
        // redirect: '/login',
        component: async () => h(RouterView),
        props: true,
        meta: {
          requiresAdmin : true,
          title: `${COMPANY_NAME}: Application`
        },
        children: [
          {
            path: 'review',
            component: PortalReview,
            props: true,
            meta: {
              requiresAdmin : true,
              title: `${COMPANY_NAME}: Review your application`
            },
          },  
          {
            path: 'view',
            component: PortalView,
            props: true,
            meta: {
              requiresAdmin : true,
              title: `${COMPANY_NAME}: Review your application`
            },
          },  
        ]
      },
      {
        path: 'administration',
        component: PortalAdministration,
        meta: {
          requiresAdmin : true,
          title: `${COMPANY_NAME}: Broker Portal: Dashboard`
        }
      },
    ]
  },
  
  // TODO : redirect/404 for when it hits a dead page, below sorts most other 404s
  // TOOD: messes up with portal, leave off and change to a routerguard. 
  // { 
  //   path: '/:pathMatch(.*)*',
  //   redirect: to => {
  //     if (!currentUser) {
  //       return { path: '/login' } 
  //     }
  //     return {   path: brokerUser.value === 'broker' ? '/broker/dashboard' : '/dashboard' } 
  //   }
  // }
]

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

// Add a helper function to determine required data
const getRequiredData = (to: any) => {
  return {
    needsMortgages: to.path.includes('/mortgage') || to.path === '/dashboard',
    needsApplications: true, // Always needed for breadcrumbs
    needsReview: to.path.includes('/review'),
    needsUserReview: to.path.includes('/review') || to.path.includes('/summary'),
    needsAiReview: to.path.includes('/review'),
    needsBrokerReview: to.path.includes('/broker') && to.path.includes('/review'),
    isApplicationRoute: to.path.includes('/application/'),
  }
}

router.beforeEach(async (to, from) => {
  // Update page titles
  console.debug('1.0.7', to)
  if (typeof(to.meta.title) === 'string') {
  document.title = to.meta.title
  }
  // whether the page was reloaded
  const reloadedPage = from === START_LOCATION ? true : false
  
  // whether logging in
  const loggingIn = to.path === '/login-check' ? true : false
  const dashboard = to.path === '/dashboard' ? true : false
  const review = to.path.indexOf('/review') > 0 ? true : false
  const summary = to.path.indexOf('/summary') > 0 ? true : false
  const securePage = to.meta.requiresAuth
  const brokerPage = to.meta.requiresAdmin
  const brokerAdministration = to.path === '/broker/administration' ? true : false

  console.log('brokerPage', brokerPage)

  // const additionalApplicant = from.query.aa ? true : false 

  // console.log('from.query.aa', from.query.aa)
  // TODO redirect when id's in path don't match store/fb
  // TODO clear local cache when session end / logged out
  // TODO check store hydration
  // TODO: clear store
  // store.$dispose()
  // console.debug('storedispose', store)

  // get the uuid of the user
  userUuid.value = currentUser.value?.uid!

  // console.log('from === START_LOCATION', from === START_LOCATION)


  if (loggingIn ) {
    // TODO: Check querystring and push to previous page
    // check for FB user else send to login page
    if (!currentUser.value ) {
      console.error('No user')
      return {
        path: `/login`
      }
    }   
    // if no applications
    if (brokerUser.value) {
      return {
        path: `/broker/dashboard`
      }
    } else {

    const store:any = useCombinedStore(pinia)
    // update/create the stored page id
    store.helpers.pageId = to.params.id

    await store.getMortgages('mortgages')
    // TODO: check if the above is needed
    // fetch applications if not already fetched
    await store.fetchApplications()
      
    console.log('store.mortgage_applications', store.mortgage_applications.value)
    // check if there are any applications
    if(!store.mortgage_applications?.length) {
      // Get a uuid for the new user
      console.log('totalApplications', 0)
      store.helpers.pageId = uuid()

      // create the new application and send to mortgage page
      await firebaseCreateCollection(
        {
          main_applicant_id: currentUser?.value?.uid,
          brokerage: import.meta.env.VITE_BROKERAGE, 
          brokers: ['Di80ftfQXOzOCEpcvEYZ0snTWlpW'], // TODO: get from store
          sent_to_crm: false
        },
        'applications',  store.helpers.pageId)

      // TODO Error handle this
      return {
        path: `/application/${store.helpers.pageId}/mortgage`
      }
      // redirect them to dashboard if multiple applications
      } else if (store.mortgage_applications.length === 1) { 

      // TODO: check steps and push to correct step page
      console.debug("totalApplications = 1")   
      store.helpers.pageId = store.mortgage_applications[0].id
      // TODO Error handle this
      return {
        path: `/application/${store.helpers.pageId}/mortgage`
      }
    } else if (store.mortgage_applications.length >= 1) { 
      console.debug("totalApplications >= 1")   
      // TODO Error handle this
      return {
        path: `/dashboard/`
      } 
    }
  } 
  }
    

  // check for auth
  if(securePage) {
    // check for FB user else send to login page
    if (!currentUser.value ) {
      console.error('No user')
      return {
        path: `/login`
      }
    }   
    if (brokerUser.value) {
      console.error('Broker user')
      return {
        path: `/broker/dashboard`
      }
    }   

    const store: any = useCombinedStore(pinia)
    const required = getRequiredData(to)

    // Set pageId
    store.helpers.pageId = to.params.id

    // Load only what's needed and not already loaded
    const loadingPromises = []

    if (required.needsApplications && !store.applications?.length) {
      loadingPromises.push(store.fetchApplications())
    }

    if (required.needsMortgages && !store.mortgages?.length) {
      loadingPromises.push(store.getMortgages('mortgages'))
    }

    // Only initialize store for application routes if not already initialized
    if (required.isApplicationRoute && !store.isInitialized) {
      loadingPromises.push(store.initStore())
    }

    // Load review data only when needed
    if (required.needsReview) {
      if (required.needsAiReview && !store.aiReview) {
        loadingPromises.push(store.getAiReview())
      }
      if (required.needsUserReview && !store.userReview) {
        loadingPromises.push(store.getUserReview())
      }
    }

    // Wait for all necessary data to load
    if (loadingPromises.length > 0) {
      await Promise.all(loadingPromises)
    }

    // Update current application if needed
    if (required.isApplicationRoute) {
      store.currentApplication = store.getCurrentApplication
    }

    // Redirect checks
    if (!summary && store.userReviewComplete) {
      return {
        path: `${to.path.substring(0,to.path.lastIndexOf('/'))}/summary`
      }
    } 
    if (!review && !summary && store.docUploadComplete) {
      return {
        path: `${to.path.substring(0,to.path.lastIndexOf('/'))}/review`
      }
    }
  } 
  // broker portal
  else if (brokerPage) {
      // check for FB user else send to login page
      if (!currentUser.value ) {
        console.error('No user')
        return {
          path: `/login`
        }
      }   

      const brokerDashboard = to.path === '/broker/dashboard' ? true : false
    
      // check for FB user else send to login page
      if (!currentUser.value || brokerUser.value !== 'broker') {
        console.error('No user')
        return {
          path: `/login`
        }
      }   

      // Get Broker applications
      const portalStore:any = usePortal(pinia)

      // TODO: Add back if needed for additional personalisation
      // await portalStore.getBrokerData()
      await portalStore.getBrokerApplications()

      // fetch brokers
      if (brokerAdministration) {
        await portalStore.fetchBrokers()
      }

      if (!brokerDashboard && !brokerAdministration) {

        console.log('!brokerDashboard', to.params.id, to.params?.uuid.toString())
        // get the uuid of the user
        userUuid.value = to.params?.uuid.toString()

        const store:any = useCombinedStore(pinia)
        store.helpers.pageId = to.params.id

        // load all applicant data
        await store.getMortgages('mortgages')
        await store.initStore()
        await store.getAiReview()
        await store.getUserReview()
        await store.getBrokerReview()
      }

    
    }

  
})

export default router
