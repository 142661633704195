//  firebase imports
// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { 
  getFirestore, 
  setDoc, 
  doc, 
  collection, 
  getDocs, 
  serverTimestamp,
  connectFirestoreEmulator,
  updateDoc,
  arrayUnion,
  arrayRemove,
  writeBatch,
  deleteDoc,
  query,
  where,
  or,
  getDoc,
  collectionGroup,
  onSnapshot, 
  // addDoc, 
  // documentId,
  // query, 
  // orderBy,
  // where,
  // getDoc,
  // DocumentSnapshot,
  // Timestamp,
  // DocumentData,
  // onSnapshot,
  // collectionGroup,
} from 'firebase/firestore'
import { 
        // Auth, 
        connectAuthEmulator, 
        getAuth,
        onAuthStateChanged,
        signInWithEmailLink,
        isSignInWithEmailLink,
        sendSignInLinkToEmail,
        signOut,
        User,
        getIdTokenResult,
        createUserWithEmailAndPassword
      } from "firebase/auth"
import {
  getStorage, 
  ref as storageRef, 
  uploadBytesResumable, 
  getDownloadURL, 
  connectStorageEmulator,
  deleteObject,
} from "firebase/storage"
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { getMessaging, getToken } from "firebase/messaging";
import { ref } from "vue"
import {v4 as uuid} from 'uuid'
import { useCombinedStore, usePortal } from "./pinia-store"

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// TODO: Sandbox/UAT firebase Environments and move to .env files
const firebaseConfig = {
  apiKey: "AIzaSyCHDr_Dh23QJdEW9B6qK-ET81vCcQaK12Q",
  authDomain: "maistro-backend.firebaseapp.com",
  projectId: "maistro-backend",
  storageBucket: "maistro-backend.appspot.com",
  messagingSenderId: "883703824234",
  appId: "1:883703824234:web:0bed751d9548d7de9e2f13",
  measurementId: "G-WENY4D8F4M"
}

// firebase settings for Auth email link generation
const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: 'https://maistro-backend.web.app/login?' ,
  handleCodeInApp: true,
  // iOS: {
  //   bundleId: 'com.example.ios'
  // },
  // android: {
  //   packageName: 'com.example.android',
  //   installApp: true,
  //   minimumVersion: '12'
  // },
  // dynamicLinkDomain: 'example.page.link'
}

export const firebaseApp = initializeApp(firebaseConfig)

// init firestore
export const db = getFirestore(firebaseApp)

// init Functions
// const functions = getFunctions( firebaseApp ); 
const functions = getFunctions( firebaseApp, "australia-southeast1" )
 
// init storage
const storage = getStorage();

// init cloud messaging - 
const cloudMessaging = ()=> {
  const messaging = getMessaging(firebaseApp);

  const vapidKey = 'BEglZZ76fEjAjf_bNMU85lb_GFU79bPsf-vu9l9llTDGo05OiMAmXIrS47pqAbpUdueyCPokpUXhAxJAi0W4FEc'
  // getToken(messaging, {vapidKey: vapidKey});
  
  // TODO: Only run this at a certain point in the App
  // Vite seems to not serve the JS file in the public folder. 
  // https://firebase.google.com/docs/cloud-messaging/js/receive
  getToken(messaging, { vapidKey: vapidKey }).then((currentToken) => {
    if (currentToken) {
      console.log('token', currentToken)
      // Send the token to your server and update the UI if necessary
      // ...
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
      // ...
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
  });
}



if(location.hostname === "localhost") {
  // emulators
  connectFirestoreEmulator(db, "localhost", 8080)
  connectAuthEmulator(getAuth(), "http://localhost:9099")
  connectStorageEmulator(getStorage(), "localhost", 9199)
  connectFunctionsEmulator( functions, "localhost", 5001 );
  //email link sent to the firebase emulator console
  actionCodeSettings.url = "http://localhost:5173/login?"

}


interface storageUpload {
  type?: string,
  applicantId?: string, 
  ehId?: string, // employment_history id  
  originalFileName?: string,
  contentType?: string,
  fileName?: string,
  uri?: string,
  uid?: string,
  ref?: string,
  progress?: number,
  error?: boolean,
  otherData: object
}
/**
 * 
 */
/**
 * Upsert to Firebase dependant on id value 
 *
 * @param file - 
 * @param folder - 
 * @throws Never TODO
 * @returns standard FB return TODO: Make interface
 */
export const firebaseStorageUpload = async (
formId: string,
file: any,
type: string,
folder: string,
pageId: string,
otherData: object,
applicant_id: string,
eh_id: string
):Promise<storageUpload> => {
  const store:any = useCombinedStore()
  console.log('folder', type)
  const extension = file.name.split(".").pop();
  const newFileName = `${type}_${Date.now()}.${extension}`

  // Create the file metadata
  /** @type {any} */
  const metadata = {
    contentType: file.type,
    customMetadata: {
      contentType: file.type,
      originalFileName: file.name,
      pageId: pageId,
      type: type,
      ref: `/users/${userUuid.value}/applications/${pageId}/${folder}/${newFileName}`
    } 
  }

    return new Promise(function(resolve, reject) {
      // Upload file and metadata to the object
      const ref = storageRef(storage, metadata.customMetadata.ref)
      const uploadTask = uploadBytesResumable(ref, file, metadata);
      const  data:storageUpload = {
        type: type,
        applicantId: applicant_id,
        contentType: file.type,
        originalFileName: file.name,
        fileName: newFileName,
        uid: uuid(),
        ref: metadata.customMetadata.ref, 
        otherData

      }

      if(eh_id) {
        data.ehId = eh_id
      }

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on('state_changed',
        async (snapshot: any) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
          store.helpers.progressIndicators[formId] = progress
          // data.progress = progress
          // console.log('Upload is ' + progress + '% done');
        },
        (error: any) => {
          reject(error)
        },
        async () => {
          // Upload completed successfully, now we can get the download URL
          await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL:string) => {
            console.log('File available at', downloadURL)
            data.uri = downloadURL
            store.helpers.progressIndicator[folder] = null
          });

          // add document to document FS ref
          const addDocumentToFS = await firestoreUpsertSubcollection(data, "delete", pageId, "documents", data.uid!, false)

          resolve(data)

          
        })
  })
}

/**
 * 
 */
/**
 * Delete from FB Storage 
 *
 * @param fileLocation - 
 * @param uid - uuid of the document
 * @throws Never TODO
 * @returns standard FB return TODO: Make interface
 */
export const firebaseStorageDelete = async (
  fileLocation: string,
  uid: string
) => {
  const store = useCombinedStore()
  try {
    // Create a reference to the file to delete
    const ref = storageRef(storage, fileLocation)

    // Delete the file
    await deleteObject(ref).then(async () => {
      // File deleted successfully
      // Delete Document
      const deleteResult = await firestoreDeleteDocument("users", 
      store.helpers.pageId!, 
      "documents", 
      uid)

    }).catch((error:any) => {
      console.log(error)
    })

  } catch (error) {
    console.log(error)
  }
}

/**
 * 
 */
/**
 * Upsert to Firebase dependant on id value 
 *
 * @param data - A reference to collection inserted data.
 * @param collectionName - The value of the collection e.g. mortgage_applications
 * @param id - the id to point to the collection
 * @throws Never TODO
 * @returns standard FB return TODO: Make interface
 */
export const firebaseUpdateCollection =  async ( data:any, collectionName:string,
  pageId: string, 
  // additionalObject?: string
  ) => {
  
  const store:any = useCombinedStore()
  // const id = pageId ? store.helpers.pageId: userUuid.value
  const id = pageId

  const docRef = doc(db, `users/${userUuid.value}/${collectionName}/${id}`)

  let errorMessage = ""
  let dataToCreate = {
    ...data,
    update_timestamp: serverTimestamp(),
  }
  
  try {
    const createPromise =
    await updateDoc(docRef, 
      dataToCreate
    ).then(async () => id).catch( (e) => { 
      console.error(e)
      errorMessage = e
    } )
 
    const docId = await createPromise

    return {
    id: docId,
    ...data,
    error: errorMessage,
    updateTimestamp: serverTimestamp()
    } 
  } catch (error) {
    return {
      error: error
    }
  }
}

/**
 * 
 */
/**
 * Delete Firestore Document
 *
 * @param collectionName - The value of the collection e.g. mortgage_applications
 * @param id - the id to point to the collection
 * @param subcollectionName - subcollection Name
 * @param subcollectionId - the subcollection id (stored as id on the item)
 * @throws Never TODO
 * @returns TODO
 */
export const firestoreDeleteDocument = async (  
  collectionName:string, 
  id:string, 
  subcollectionName: string, 
  subcollectionId: string,
  ) => {

  try {
    const store = useCombinedStore()
    const docRef = doc(db, `/users/${userUuid.value}/applications/${store.helpers.pageId}/${subcollectionName}/${subcollectionId}`)
    return await deleteDoc(docRef)
  } catch (error) {
    return error
  }

}

/**
 * 
 */
/**
 * Upsert to Firebase dependant on id value 
 *
 * @param data - A reference to collection inserted data.
 * @param collectionName - The value of the collection e.g. mortgage_applications
 * @param id - the id to point to the collection
 * @param subcollectionName - subcollection Name
 * @param subcollectionId - the subcollection id (stored as id on the item)
 * @param update - bool, whether to update or insert
 * @throws Never TODO
 * @returns standard FB return TODO: Make interface
 */
export const firestoreUpsertSubcollection =  async ( 
  data:any,  
  collectionName:string, 
  id:string, 
  subcollectionName: string, 
  subcollectionId: string,
  update: boolean
  ) => {

  try {
    const subcollectId = subcollectionId ? subcollectionId :  uuid()
    const store = useCombinedStore()
    const docRef = doc(db, `/users/${userUuid.value}/applications/${store.helpers.pageId}/${subcollectionName}/${subcollectionId}`)

    let errorMessage = ""
    let dataToCreate = {
      id: subcollectId,
      ...data,
      update_timestamp: serverTimestamp(),
      create_timestamp: data.create_timestamp  ?   data.create_timestamp : serverTimestamp() 
    }
    
    console.debug('debug ggg', data)

    const createPromise =
    update
    ? 
    await updateDoc(docRef, dataToCreate).then(() => id).catch( (e) => { console.log(e)} )
    : // Create doc initially
    await setDoc(docRef, dataToCreate).then(() => id).catch( (e) => { console.log(e)} )
      
    const docId = await createPromise
    
    return {
      id: docId,
      ...data,
      error: errorMessage,
      updateTimestamp: serverTimestamp()
    }
  } catch (error) {
    console.log(error)
    return {
      error: error
    }
  }
  
}

// TODO: amalgamate with firestoreUpsertSubcollection
/**
 * 
 */
/**
 * Upsert to Firebase dependant on id value 
 *
 * @param data - A reference to collection inserted data.
 * @param collectionName - The value of the collection e.g. mortgage_applications
 * @param id - the id to point to the collection
 * @param subcollectionName - subcollection Name
 * @param subcollectionId - the subcollection id (stored as id on the item)
 * @param update - bool, whether to update or insert
 * @throws Never TODO
 * @returns standard FB return TODO: Make interface
 */
export const firestoreUpsertReview =  async ( 
  data:any,  
  arrayName:string,
  user: boolean
  ) => {

  // const subcollectionPath = false ?  `/${subcollectionName}/${subcollectionId}` : ''
  // const docRef = doc(db, `/users/${userUuid.value}/${collectionName}/${id}${subcollectionPath}`)
  // check whether subcollectionId is created and 
  try {
    const store:any = useCombinedStore()
    const batch = writeBatch(db);
    
    // find the user review document
    const docRef = doc(db, `/users/${userUuid.value}/applications/${store.helpers.pageId}/reviews/${user ? 'user' : 'broker'}`)
    const docSnap = await getDoc(docRef)
    const existingData = docSnap.data()

    // check that user review data exists
    if (docSnap.exists() && existingData) {
      const element = docSnap.data()[arrayName]

      // First we need to remove the FS object if it exists
      if (element) {
        
        // Find matching ids between local and FS user review data 
        const removeTarget = element.find((item:any) => {
         
          return item.id === data.id
        })
                
        // Remove existing array in FS
        if (removeTarget) {
          batch.update(docRef, {[arrayName]: arrayRemove(removeTarget)});
        }

        // add data to FS
        batch.update(docRef, {[arrayName]: arrayUnion(data)});

      } else {
        // add new array to FS
        batch.update(docRef, {[arrayName]: arrayUnion(data)});
      }
    } else {
      // create user review data
      batch.set(docRef, {[arrayName]: [data]})
    }
    
    const createPromise = await batch.commit()
      .then(() =>  { 
        return true
      })
      .catch(err => {
        return false
      })
  
      const batchReturn  = await createPromise
    
      return {
        batchReturn,
        data,
        updateTimestamp: serverTimestamp()
      }

  } catch (error) {
    return error
  }

}


// TODO: amalgamate all of these batch.update methods to create a single one by making the ref a varaible

/**
 * 
 */
/**
 * get all applicants subcollection and then take relevant data and save to applicants top level
 *
 * @param data - A reference to collection inserted data.
 * @param collectionName - The value of the collection e.g. mortgage_applications
 * @param id - the id to point to the collection
 * @param subcollectionName - subcollection Name
 * @param subcollectionId - the subcollection id (stored as id on the item)
 * @param update - bool, whether to update or insert
 * @throws Never TODO
 * @returns standard FB return TODO: Make interface
 */
export const firestoreUpdateApplicants =  async ( 
  ) => {

  try {
    const store:any = useCombinedStore()
    
    // get all applicants
    const collectionRef = collection(db, `users/${userUuid.value}/applications/${store.helpers.pageId}/applicants`)
    
    const docs = await getDocs(collectionRef)

    const applicants = docs.docs.map((d) => {
      return {id: d.id, ...d.data()}
    })
    console.log('docs', applicants)

    // write data to application
    await updateDoc(doc(db, `users/${userUuid.value}/applications/${store.helpers.pageId}`), {applicants}).catch( (e) => { console.log(e)} )
  
  } catch (error) {
    console.log(error, 'batrchoretrernerrr')

    return error
  }
}


export const firestoreUpdateSteps =  async ( 
  type:string,  
  ) => {

  // const subcollectionPath = false ?  `/${subcollectionName}/${subcollectionId}` : ''
  // const docRef = doc(db, `/users/${userUuid.value}/${collectionName}/${id}${subcollectionPath}`)
  // check whether subcollectionId is created and 
  try {
    const store:any = useCombinedStore()
    const batch = writeBatch(db);

    // find the user review document
    const docRef = doc(db, `/users/${userUuid.value}/applications/${store.helpers.pageId}/`)
    const docSnap = await getDoc(docRef)
    const existingData = docSnap.data()

    // check that user review data exists
    if (docSnap.exists() && existingData) {
      const element = docSnap.data()['steps']
          // console.log("item",)

      // First we need to remove the FS object if it exists
      if (element) {
        // Find matching ids between local and FS user review data 
        const removeTarget = element.find((item:any) => {
          // console.log("item", item)
          // console.log("data", data.id)
          return item === type
        })

        // console.log("removeTarget", removeTarget)
                
        // // Remove existing array in FS
        if (removeTarget) {
          batch.update(docRef, {'steps': arrayRemove(removeTarget)});
        }

        // // add data to FS
        batch.update(docRef, {['steps']: arrayUnion(type)});

      } else {
        // add new array to FS
        batch.update(docRef, {['steps']: arrayUnion(type)});
      }
    } 
    
    const createPromise = await batch.commit()
      .then(() =>  { 
        return true
      })
      .catch(err => {
        return false
      })
  
      const batchReturn  = await createPromise
    
      return {
        batchReturn,
        updateTimestamp: serverTimestamp()
      }

  } catch (error) {
    return error
  }

}


/**
 * Create to Firebase dependant on id value TODO update id value
 */
export const firebaseCreateCollection =  async ( data:any, collectionName:string, id:string) => {
  try {
    // const collectionRef = collection(db, `mortgage_application/${id}/${collectionName}`)
    let errorMessage = ""
    const dataToCreate = {
      ...data,
      steps: ['created'],
      create_timestamp: serverTimestamp(),
      update_timestamp: serverTimestamp(),
    }
    
    const docId =
          await setDoc(doc(db, `users/${userUuid.value}/${collectionName}/${id}`), dataToCreate).then(() => id).catch( (e) => { 
            console.error(e)
            errorMessage = e
          } )
  
    return true
  } catch (error) {
    console.error(error)
  }
}


// Get applicant collection
export const fireBaseGetUserData =  async () => {
  const docRef =  collection(db, `users/${userUuid.value}/applications`)
  const docSnap = await getDocs(docRef);

  const data = docSnap.docs.map((d) => {
        return {id: d.id, ...d.data()}
      })
  return data
}

// Broker Portal

// export const fireBaseGetBrokerData =  async () => {
//   // TODO: Add to users create Brokerages and Brokers then assign them to users/applications
//   try {
//     const user = currentUser.value
//     // return (await getDoc(docRef)).data();
    
   
//   } catch (error) {
//     console.log('error')
//     return error
//   }
// }

// export const fireBaseGetBrokerApplications = () => {
//   const portalStore = usePortal();

//   try {
//     const q = query(
//       collectionGroup(db, 'applications'),
//       where('brokerage', '==', 'au-first-street'),
//       where('brokers', 'array-contains-any', [userUuid.value])
//     );

//     // Directly update the store or state
//     return onSnapshot(q, (querySnapshot) => {
//       const applications = querySnapshot.docs.map((d) => {
//         return { id: d.id, ...d.data() };
//       })
      
//     }, (error) => {
//       console.error('Error getting broker applications:', error);
//     });

//   } catch (error) {
//     console.log('error', error);
//     return () => {}; // Return empty function in case of error
//   }
// };

/**
 * 
 */
/**
 * Upsert to Firebase dependant on id value 
 *
 * @param data - A reference to collection inserted data.
 * @param collectionName - The value of the collection e.g. mortgage_applications
 * @param id - the id to point to the collection
 * @param subcollectionName - subcollection Name
 * @param subcollectionId - the subcollection id (stored as id on the item)
 * @param update - bool, whether to update or insert
 * @throws Never TODO
 * @returns standard FB return TODO: Make interface
 */
export const firestoreBrokerUpdateArchive =  async ( 
  application_id:string, 
  userId: string,
  archived: boolean,  
  ) => {

  try {
    const docRef = doc(db, `/users/${userId}/applications/${application_id}/`)

    await updateDoc(docRef, {archived: archived})
    
  } catch (error) {
    console.log(error)
    return {
      error: error
    }
  }
}

// Add broker
export const firestoreAddBroker = async (formData:any) => {
  console.log('firestoreAddBroker', formData)  
  try {
    // const docRef = doc(db, `/brokerages/${import.meta.env.VITE_BROKERAGE}/brokers/`)

    const setClaims = httpsCallable(functions, "setCustomClaims");


    // await setDoc(docRef, formData)
    // TODO: create a broker first and return the id of the created broker
    // await createFirebaseUser(formData.email, password, formData.brokerAdministrator)
    const data = {
      role: "broker",
      brokerage: import.meta.env.VITE_BROKERAGE,
      brokerAdministrator: formData.brokerAdministrator,
      email: formData.email,
      brokerName: formData.brokerName,
      archived: false,
    }
    // await setDoc(docRef, formData).then(() => id).catch( (e) => { console.log(e)} )
    await setClaims( 
      data
    )

    return {
      success: true
    }

  } catch (error) {
    console.log(error)
    return {
      success: false,
      error: error
    }
  }
} 

// Update broker
export const firestoreUpdateBroker = async (formData:any) => {
  console.log('firestoreUpdateBroker', formData)
  try {
    // update the firebase authentication user
    const editBrokerCloudFunction = httpsCallable(functions, "editBroker");
    const data = {
      role: "broker",
      brokerage: import.meta.env.VITE_BROKERAGE,
      brokerAdministrator: formData.brokerAdministrator,
      email: formData.email,
      brokerName: formData.brokerName,
      id: formData.id,
      archived: false,
      emailDirty: formData.emailDirty,
    }
    await editBrokerCloudFunction(data)

    console.log('formData', formData.id)
    // update the broker in the brokerages collection
    await updateDoc(doc(db, `/brokerages/${import.meta.env.VITE_BROKERAGE}/brokers/${formData.id}`), formData)
    return {
      success: true
    }
  } catch (error) {
    console.log(error)
    return {
      success: false,
      error: error
    }
  }
} 

// TODO: amalgamate with enableBrokerAccess

// Remove broker access
export const firestoreRemoveAccess = async (brokerId: string) => {
  console.log('firestoreRemoveAccess', brokerId)
  // Disable the broker in the authentication
  const disableBrokerCloudFunction = httpsCallable(functions, "disableBroker");
  await disableBrokerCloudFunction({uid: brokerId}) 

  // update the broker in the brokerages collection to archived
  await updateDoc(doc(db, `/brokerages/${import.meta.env.VITE_BROKERAGE}/brokers/${brokerId}`), {archived: true})
    return {
      success: true
    }
}

// Enable broker access
export const firestoreEnableBrokerAccess = async (brokerId: string) => {
  console.log('firestoreEnableBrokerAccess', brokerId)
  // Enable the broker in the authentication
  const enableBrokerCloudFunction = httpsCallable(functions, "enableBroker");
  await enableBrokerCloudFunction({uid: brokerId}) 

  // update the broker in the brokerages collection to archived
  await updateDoc(doc(db, `/brokerages/${import.meta.env.VITE_BROKERAGE}/brokers/${brokerId}`), {archived: false})

  return {
    success: true
  }
}

// Add applicant
export const firestoreAddApplicant = async (formData:any) => {
  console.log('firestoreAddApplicant', formData)
  try {
    const addApplicantCloudFunction = httpsCallable(functions, "addApplicant");
    return await addApplicantCloudFunction(formData) 
   
  } catch (error: any) {
    return error
  }
  
} 

// const createFirebaseUser = async (email: string, password: string, brokerAdministrator: boolean) => {
//   const auth = getAuth();
//   try {
//     const userCredential = await createUserWithEmailAndPassword(auth, email, password);
//     const user = userCredential.user;
//     console.log("User created:", user.uid);
//     // Call a cloud function to set custom claims
//     await setCustomClaims(user.uid, "broker", import.meta.env.VITE_BROKERAGE, brokerAdministrator);
//   } catch (error) {
//     console.error("Error creating user:", error);
//   }
// }

// const setCustomClaims = async (uid: string, role: string, brokerage: string, brokerAdministrator: boolean) => {
//   const functions = getFunctions();
//   const setClaims = httpsCallable(functions, "setCustomClaims");

//   try {
//     const result = await setClaims({ uid, role, brokerage, brokerAdministrator });
//     console.log(result.data.message);
//   } catch (error) {
//     console.error("Error setting custom claims:", error);
//   }
// };

/**
 * 
 */
/**
 * Send additional applicant an email and add them to FS
 * @param update - bool, whether to update or insert
 * @throws Never TODO
 * @returns standard FB return TODO: Make interface
 */
export const cloudFunctionSendAdditionalApplicantEmail =  async ( 
  data:any,
    ) => {
    const store = useCombinedStore()
    const sendAdditionalApplicantEmail = httpsCallable(functions, 'sendAdditionalApplicantEmail')
    
    // console.log('data', data)
    // data.userUuid = userUuid.value
    // data.pageId = store.helpers.pageId
    
    sendAdditionalApplicantEmail( data )
      .then((result) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        const data = result.data;
        console.log('sent additional applicant', data)
        // const sanitizedMessage = data.text;
      }).catch((error) => {
        console.log( "error", error );
        console.log( "code", error.code );
        console.log( "message", error.message );
        console.log( "details", error.details );
      })
  }
  


// export const fireStoreGetAllSubcollections = async (subcollectionName: string, pageId: string ) => {
//   let collectionRef = collection(db, `/users/${userUuid.value}/mortgage_applications/${pageId}/${subcollectionName}`)

//   // const data = onSnapshot(collectionRef, (querySnapshot) => {
//   //   querySnapshot.forEach((doc) => {
//   //     return {id: doc.id, ...doc.data()}
//   //   })
//   // })
//   const firebaseCollectionData = await getDocs(collectionRef)
//   // console.log("firebaseCollectionData", firebaseCollectionData)
  
//   const response = await firebaseCollectionData
//   const data = response.docs.map((d) => {

//     // account for weird formkit setup with multi-step
//     if (subcollectionName === 'applicants') {
//       return {applicant: {id: d.id, ...d.data()}}
//     }
//       return {id: d.id, ...d.data()}
//         })
//   return data
// }


/**
 * 
 */
/**
 * Send vehicle details to AI 
 * @param update - bool, whether to update or insert
 * @throws Never TODO
 * @returns standard FB return TODO: Make interface
 */
export const cloudFunctionVehicleToAI =  async ( 
data:any
  ) => {
  const store = useCombinedStore()
  const checkVehicleValue = httpsCallable(functions, 'sendVehicleToAi')
  
  // console.log('data', data)
  data.userUuid = userUuid.value
  data.pageId = store.helpers.pageId
  
  checkVehicleValue( data )
    .then((result) => {
      // Read result of the Cloud Function.
      /** @type {any} */
      const data = result.data;
      // const sanitizedMessage = data.text;
    }).catch((error) => {
      console.log( "error", error );
      console.log( "code", error.code );
      console.log( "message", error.message );
      console.log( "details", error.details );
    })
}

// Submit application to AI
export const cloudFunctionSubmitApplication =  async ( 
  ) => {
    let returnVal = false
    const store = useCombinedStore()
    const runCloudFunction = httpsCallable(functions, 'processApplication')
    
    const data = {
      userUuid: userUuid.value,
      pageId: store.helpers.pageId
    }
   
    await runCloudFunction( data )
      .then((result) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        const data = result.data;
        // const sanitizedMessage = data.text;
        returnVal = true 
      }).catch((error) => {
        console.log( "error", error );
        console.log( "code", error.code );
        console.log( "message", error.message );
        console.log( "details", error.details );
        returnVal = false
      })

      return returnVal
  }

  // Send application to CRM
  export const cloudFunctionSendApplicationToCRM = async (data:any) => {
    const createConnectiveContact = httpsCallable(functions, 'createConnectiveContact')
    try { 
      return await createConnectiveContact(data)
    } catch (error) {
      console.log('error', error)
      return error
    }
  }


  //
  export const runPuppeteerCommands = async () => {
    const puppeteer = httpsCallable(functions, "runPuppeteerCommands");

    await puppeteer({
      puppeteerFile: 'puppeteer-commands/example.ts',
      fileData: { type: 'application/json', content: JSON.stringify({ field1: 'value1', field2: 'value2' }), name: 'data.json' }
    })
  }

  export const convertFileToJson = async (fileData:any) => {
    // console.log(`fileData Firebase Service`, fileData);

    const store = useCombinedStore()
    
    const convertFileToJson = httpsCallable(functions, "convertFileToJson");

      // Convert file to base64 string for transmission
      const reader = new FileReader();
      const fileContent = await new Promise((resolve) => {
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(fileData);
      });

      // Remove data:application/pdf;base64, prefix
      const base64Content = (fileContent as string).split(',')[1];

      // send to cloud function and return the file data json 
      const response = await convertFileToJson({
        fileData: {
          type: fileData.type,
          content: base64Content,
          name: fileData.name
        }
      });

      console.log('response', response.data)

      // send to Firebase applicant collection
      const applicantCollectionRef = collection(db, `/users/${userUuid.value}/applications/${store.helpers.pageId}/broker`)
      await setDoc(doc(applicantCollectionRef, 'cover_sheet'), response?.data)

      return response
  }

// Auth Functions
export const currentUser = ref<User | null>(null);
export const brokerUser = ref();
export const userUuid = ref<String | null>(null)
export const brokerage = ref()
export const brokerAdministrator = ref(false)

export const initializeFirebase = () => {
  return new Promise((resolve) => {
    onAuthStateChanged(getAuth(), async (user) => {
      if (user) {
        // User is signed in
        currentUser.value = user; 
        console.debug('userid', currentUser.value);
        
        try {
          // get the broker role
          const idTokenResult = await getIdTokenResult(user);
          brokerUser.value = idTokenResult.claims.role
          brokerage.value = idTokenResult.claims.brokerage
          brokerAdministrator.value = idTokenResult.claims.brokerAdministrator as boolean
          console.debug('Custom Claims:', idTokenResult.claims.role);
        } catch (error) {
          console.error("Error fetching custom claims:", error);
        }
        
      } else {
        // User is signed out
        // console.debug("No User");
      }
      resolve(currentUser.value);
    })
  })
}

interface signInReturn {
  success: boolean,
  error: {
  code?: string,
  message?: string,
  toast?: string,
  otherError?: any
  },
}
// sign in with email link
export const firebaseSignInWithEmailLink = async (
  email: string,
  url: string,
):Promise<signInReturn> => {
  try {
    
    const returnValues: signInReturn = { 
      success: true,
      error: {
      }
    } 

    const response = await signInWithEmailLink( 
      getAuth(), 
      email,
      url
      ).catch(error => {

        returnValues.success = false
        returnValues.error.code = error.code
        returnValues.error.message = error.message
        console.log('returnValues.error.message', returnValues.error.message)
        switch (error.code) {
          case "auth/invalid-action-code": 
          returnValues.error.toast = "The email link has expired, check your email for a new one"
            break;
            case "auth/invalid-email":
              returnValues.error.toast = "The email provided is incorrect. Please update your email."
              break;
            case "auth/user-disabled":
              returnValues.error.toast = "Your account has been disabled. Please contact us."
                break;
            default:
              returnValues.error.toast = "Something has gone wrong, please try again" 
            break;
        }
      })
      
    return returnValues
  } catch (e) {
    return {
      success: false,
      error: {
      otherError: e
    }}
  }
}

export const firebaseIsSignInWithEmailLink = async (
  emailLink: string,
) => {
  try {
    console.log('emaillink', emailLink)
    const resp = await isSignInWithEmailLink(
      getAuth(), 
      emailLink
      );
      console.log('firebaseIsSignInWithEmailLink resp', resp)
    return resp
  } catch (error) {
    return {error};
  }
}

export const firebaseSendSignInLinkToEmail = async (
  email: string,
  additional_applicant?: boolean
) => {
  try {
    // send additional params for additional applicant
    if(additional_applicant) {
      actionCodeSettings.url =  location.hostname === "localhost" ? "http://localhost:5173/login?aa=true" : 'https://maistro-backend.web.app/login?aa=true' 
    }

   

    await sendSignInLinkToEmail(getAuth(), email, actionCodeSettings);
    return {data: true}
  } catch (error) {
    return {data: error};
  }
}


export const firebaseSignOut = async () => {
  const store = useCombinedStore()
  // const vehicle = useVehicleStore()

  // typeof store.unsubReviewDocuments === "function" ? await store.unsubReviewDocuments() : null
  // TODO: Add unsub for all combined store
  // typeof vehicle.unsubVehicleDocuments === "function" ? await vehicle.unsubVehicleDocuments() : null
  // typeof document.unsubDocuments === "function" ? await document.unsubDocuments() : null
  // typeof document.unsubEmployers === "function" ? await document.unsubEmployers() : null

  return await signOut(getAuth());
}
