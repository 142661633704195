import en from '@vueform/vueform/locales/en'
import tailwindMaterial from '@vueform/vueform/dist/tailwind-material'
import { defineConfig, Validator } from '@vueform/vueform'
import PluginMask from '@vueform/plugin-mask'
import CustomElement from '@/components/LocalButtonElement.vue'

// Vueform validation filters
en.validation.required = ':attribute is required'
en.validation.containsspace = 'The applicant\'s full name must contain a space'

const containsspace = class extends Validator {
  check(value:any) {
    return value? value.includes(' ') : false
  }
}

// const propertyYearsOld = class extends Validator {
//   check(value) {
//     return value.includes(1)
//   }
// }

const customRule = class extends Validator
{
  get valueToWatch () {
    console.log('this.attributes[0] ', this.attributes[0] )
    return this.attributes[0] 
  }

  // init() {
  //   watch(this.valueToWatch, () => {
  //     this.revalidate()
  //   })
  // }

  check(value:any) {
    // console.log('value', value)
    return value
    // ...
  }
}

export default defineConfig({
  theme: tailwindMaterial,
  locales: { en },
  locale: 'en-AU',
  plugins: [
    PluginMask,
  ], 
  rules: {
    containsspace,
    // propertyYearsOld
    customRule,
  },
  // templates: {
  //   ButtonElement: CustomElement,
  // },
  elements: [
    CustomElement,
  ],

})
